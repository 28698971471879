<template>
  <div class="vh-100">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h5>Transfer summary</h5>
            </div>
            <div class="card-body">
              <pre> {{ payment }} </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payment: {}
    };
  },
  methods: {
    fnApiGetOrder() {
      axios
        .get("payouts/" + this.$route.params.id)
        .then(response => {
          this.payment = response.data;
        })
        .catch();
    },

    fnApiDeleteOrder() {}
  },
  mounted() {
    this.fnApiGetOrder();
  }
};
</script>
